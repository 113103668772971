import React, { useEffect, useState } from "react"; 
import clientService from "../services/clientservice";
import { useTranslation } from "react-i18next";

function AutocompleteClientDropDown({
  storeClientKeyHandler = () => {}, // Default to empty function
  patchClientId = null,
  onChange = () => {}, // Default to empty function
  value = "", // Default empty value
  clearflag = false,
}) {
  const [search, setSearch] = useState(value);
  const [suggestions, setSuggestions] = useState([]);
  const { t } = useTranslation("global");

  // Handle input change
  const handleInputChange = async (event) => {
    const value = event.target.value;
    setSearch(value);

    if (value !== "") {
      try {
        const response = await clientService.getAutoCompleteClientsearch({
          searchBy: value,
          client_id: patchClientId || null,
        });
        setSuggestions(response?.rows || []);
      } catch (error) {
        console.error("Error fetching autocomplete suggestions:", error);
      }
    } else {
      setSuggestions([]);
      onChange(null); // Notify react-hook-form that the input is cleared
    }
  };

  // Handle selection of a suggestion
  const setSearchKeyhandler = (suggestion) => {
    const fullName = `${suggestion.surname} ${suggestion.client_name}`;
    setSearch(fullName);
    storeClientKeyHandler?.(suggestion);
    setSuggestions([]);
    onChange?.(suggestion); // Pass the selected value back to react-hook-form
  };

  // Patch client data if patchClientId is provided
  const patchclientKeyHandler = async () => {
    if (!patchClientId) return;
    
    try {
      const response = await clientService.getAutoCompleteClientsearch({
        client_id: patchClientId,
      });
      const client = response?.rows?.[0];
      if (client) {
        const fullName = `${client.surname} ${client.client_name}`;
        setSearch(fullName);
        onChange?.(client); // Pass the patched value back to react-hook-form
      }
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };

  // Clear input if clearflag is true
  useEffect(() => {
    if (clearflag) {
      setSearch("");
      onChange?.(null); // Clear the value in react-hook-form
    }
  }, [clearflag]);

  // Fetch client data if patchClientId changes
  useEffect(() => {
    if (patchClientId != null) {
      patchclientKeyHandler();
    }
  }, [patchClientId]);

  return (
    <div>
      <input
        className="form-control"
        type="text"
        value={search}
        onChange={handleInputChange}
        placeholder={t("Rechercher des clients...")}
      />
      <ul
        className="list-group"
        style={{ overflowY: "scroll", maxHeight: "20vh" }}
      >
        {Array.isArray(suggestions) && suggestions.length > 0
          ? suggestions.map((suggestion, index) => (
              <li
                className="list-group-item"
                onClick={() => setSearchKeyhandler(suggestion)}
                key={index}
              >
                {suggestion?.surname && suggestion?.client_name
                  ? `${suggestion.surname} ${suggestion.client_name}`
                  : ""}
              </li>
            ))
          : ""}
      </ul>
    </div>
  );
}

export default AutocompleteClientDropDown;
