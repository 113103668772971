import { useTranslation } from "react-i18next";



export const GetTranslatedConstants = () => { 
  const { t } = useTranslation("global");
  
const frequency_services=[
  {
    value: "Session unique",
    label: t("Session unique"),
  },
  {
    value: "Hebdomadaire",
    label: t("Hebdomaire"),
  },
  {
    value: "Mensuelle",
    label: t("Mensuelle"),
  },
  {
    value: "Autre",
    label: t("Autre"),
  },
]

const preferred_language = [
  { label: t("Français"), value: "Français" },
  { label: t("Anglais"), value: "Anglais" },
  { label: t("Autre"), value: "Autre" },
];
return {frequency_services, preferred_language }
}

const typeOfOption = [
  { label: "Membre", value: "membre" },
  { label: "Bénévole", value: "bénévole" },
  { label: "Client", value: "client" },
];

  
// const preferred_language = [
//   { label: t("Français"), value: "Français" },
//   { label: t("Anglais"), value: "Anglais" },
//   { label: t("Autre"), value: "Autre" },
// ];
const listofGender = [
  {
    value: "Femme",
    label: "Femme",
  },
  {
    value: "Homme",
    label: "Homme",
  },
  {
    value: "Autre",
    label: "Autre",
  },
];
const martial_status =[

  {
      value: "Célibataire",
      label: "Célibataire",
    },
    {
      value: "Mariée",
      label: "Mariée",
    },
    {
      value: "Veuve",
      label: "Veuve",
    },
    {
      value: "Divorcée",
      label: "Divorcée",
    },
    {
      value: "Séparée",
      label: "Séparée",
    },
    {
      value: "Union de fait",
      label: "Union de fait",
    },
    {
      value: "Autre",
      label: "Autre",
    },
]


export {listofGender,typeOfOption ,martial_status}