import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { messageLabel } from "../../components/newcustomer/labels";
import clientService from "../../../../../services/clientservice";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import Select from "react-select";
import reportservice from "../../../../../services/reportservice";
import { toast } from "react-hot-toast";
import useSettings from "../../../../../hooks";
import { FaPlus, FaRegTrashAlt } from "react-icons/fa";
import CreatableSelect from "react-select/creatable";
import AutocompleteClientDropDown from "../../../../../hooks/useClientList";
import { useTranslation } from "react-i18next";

function MessageLookup() {
  const { register, reset, setValue, setError, formState, control, watch, handleSubmit } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "to",
  });
  const emailfield = watch("to");
  const [clientIdData, setClientIdData] = React.useState(null);
  const [loader, setLoader] = React.useState(false);
  const {
    empemail,
  } = useSettings();
 
  const onSubmit = async (data) => {

 

    data.to = data?.to?.map(x => x.value).join(",");

    setLoader(true);
    if (!clientIdData) {
      data.client_name = data.client;
    }
     if(clientIdData){
      data.client_id = clientIdData?.client_id;
    }
  

    const sentmessage = await reportservice.sentmessage(data);
    if (Object.keys(sentmessage)?.length) {
      setLoader(false);
      toast.success("Message envoyé avec succès");
      reset();
    } else {
      setLoader(false);
      toast.error("Message envoyé non");
    }
   

  };


  React.useEffect(() => {
    if (emailfield?.length == 0) {
      append({ key: "", value: "" });
    }
  }, [emailfield]);


  const storeClientKeyHandler = (client) => {
     setClientIdData(client);
  };
    const clientChangehandler = (e) => {
       setValue('client', e);

      if (e === "") {

       setClientIdData(null);
     }
    
   };
   const { t } = useTranslation("global");

   return (
    <div className="mt-3">
      <Card className="p-3">
        <div className="">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="Role">
                  <Form.Label className="text-secondary fw-bold">
                    {t("Cliente")}
                  </Form.Label>
                  <AutocompleteClientDropDown
                    storeClientKeyHandler={storeClientKeyHandler}
                    onChangeinput={(e) => clientChangehandler(e)}
                    inputRef={register("client")} // Register input for validation

                  />
                  {/* {formState?.errors?.client && (
                    <p className="text-danger">
                      {formState?.errors?.client?.message}
                    </p>
                  )} */}


                </Form.Group>
              </Col>
              <Col md={6}>

                <label className="form-label text-secondary fw-bold">
               {t("Nom de l'employée")}
                  <FaPlus
                    className=" ms-2"
                    size={15}
                    onClick={() => append({ key: "", value: "" })}
                    Add
                    Option
                  />
                </label>


                {fields.map((field, index) => (
                  <div className="row mb-2" key={field.id}>
                    <div className="col-md-5">
                      <Controller
                        control={control}
                        name={`to[${index}].key`}
                        render={({ field }) => (
                          <CreatableSelect
                            placeholder={t("Sélectionner")}
                            {...field}
                            formatCreateLabel={(inputValue) =>
                              `Créer "${inputValue}"`
                            }
                            options={empemail}
                            onChange={(e) => {
                              setValue(`to[${index}].key`, e);
                              setValue(
                                `to[${index}].value`,
                                e.email
                              );
                            }}
                          />
                        )}
                      />
                    </div>
                    <div className="col-md-5">
                      <Form.Control
                        type="email"
                        {...register(`to[${index}].value`)}
                        placeholder={t("Courriel")}
                        className="placeholder-color"
                      />
                    </div>
                    <div className="col-md-2">
                      <FaRegTrashAlt
                        className="text-danger ms-2"
                        size={15}
                        onClick={() => remove(index)}
                        Remove
                      />
                    </div>
                  </div>
                ))}

              </Col>


            </Row>
            <Row>
              <Col md={12}>
                <Form.Group className="mb-3" controlId="email">
                  <Form.Label className="text-secondary fw-bold">
                   {t("Courriel supplémentaire")}
                  </Form.Label>


                  <Form.Control
                    type="email"
                    {...register("externalto")}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Form.Group className="mb-3" controlId="message_form">
                  <Form.Label className="text-secondary fw-bold">
                   {t("Envoyé par")} *
                  </Form.Label>
                  <Form.Control
                    type="text"
                    {...register("sentBy", { required:t("Ce champ est obligatoire"), })}
                  />
                  {formState?.errors?.sentBy && (
                    <p className="text-danger">
                      {formState?.errors?.sentBy?.message}
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group className="mb-3" controlId="message">
                  <Form.Label className="text-secondary fw-bold">
                    {messageLabel?.message} *
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={6}
                    type="text"
                    {...register("message", { required: t ("Ce champ est obligatoire"), })}
                  />
                  {formState?.errors?.message && (
                    <p className="text-danger">
                      {formState?.errors?.message?.message}
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <div className="col-md-3">
              <button
                className="btn btn-primary btn-sm"
                type="submit"
                disabled={loader}
              >
                {loader && (
                  <span className="spinner-border spinner-border-sm me-2"></span>
                )}
                {t("Envoyer")}
              </button>
            </div>
          </form>
        </div>
      </Card>
    </div>
  );
}

export default MessageLookup;
