import React from "react";
import { intervenanteLabel } from "../../components/newcustomer/labels";
import { useNavigate, useParams } from "react-router-dom";
import useSettings from "../../../../../hooks";
import clientService from "../../../../../services/clientservice";
import Table from "react-bootstrap/Table";
import { convertDateformate, newdateformat } from "../../../../../utils";
import Loader from "../../../../../utils/Loader";
import { useTranslation } from "react-i18next";

function ViewIntervene({ id, setAddCustomer, setClientInterveneId }) {
  const { intervenerservice, getPartners, empemail } = useSettings();
  const [intervener, setIntervener] = React.useState([]);
  const navigate = useNavigate();
  const [loader, setLoader] = React.useState(false);

  const getInterfvenerdetails = (data, defaultdata) => {
    const recordsplit = data?.split(",");
    const arr = [];

    recordsplit.map((parter) => {
      const matchingItem = defaultdata.find((item) => item?.value === parter);

      if (matchingItem) {
        arr.push(matchingItem.label);
        // console.og("Client Name:", matchingItem.label); // Add this console log
        //  console.log("@@@@@@@@@@@@",defaultdata);
      }
    });
    return arr.join(" , ");
  };

  React.useEffect(() => {
    setLoader(true);
    if (id) {
      clientService
        .getIntevinerById(id)
        .then((intervient) => {
          setIntervener(intervient);
          setLoader(false);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      clientService
        .getIntervenet()
        .then((intervient) => {
          setIntervener(intervient);
          setLoader(false);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [id, getPartners, empemail]);
  const changeNavigation = (eid) => {
    setAddCustomer(true);
    if (!id) navigate(`/clients/intervener/${eid}`);
    else {
      setClientInterveneId(eid);
    }
  };
  const { t } = useTranslation("global");

  return (
    <Table striped>
      <thead>
        <tr>
        
          {/* <th style={{ width: "10px" }}>{t("Intervenante")}</th> */}
          <th style={{ width: "170px" }}>{t("Client")} </th>
          <th style={{ width: "150px" }}> {t("Nom de l'employée")} </th>
          <th style={{ width: "170px" }}>
            {t("Mode d’intervention")}
          </th>
          <th style={{ width: "170px" }}>
           {t("Durée (en minutes)")}
          </th>
          
          <th style={{ width: "170px" }}>{t("Date de rendez-vous")}</th>
        </tr>
      </thead>
      <tbody>
        {intervener?.length ? (
          intervener?.map((emp, idx) => (
            <tr
              key={idx}
              style={{ cursor: "pointer" }}
              onClick={() => {
                changeNavigation(emp.inter_id);
              }}
            >
                            {/* <td>{idx + 1}</td> */}

                <td>
                {emp?.client_name ? emp?.client_name:'-'}
                 

              </td>

              <td>
                {empemail?.filter((x) => x?.value == emp?.empname)[0]?.label ||
                  "-"}
              </td>

              <td>{emp?.mode_of_intervention || "-"}</td>
              <td>{emp?.intervener_minutes || "-"}</td>

            

              <td> {newdateformat(emp?.appointment_date) || "-"}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={6} className="text-center">
              {loader ? <Loader /> : "Aucun Intervention trouvé"}
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}

export default ViewIntervene;
