import React from "react";
import { useFieldArray, useForm } from "react-hook-form";
import Form from "react-bootstrap/Form";
import { toast } from "react-hot-toast";
import { Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { FaPlus, FaRegTrashAlt } from "react-icons/fa";
import clientService from "../../../../../services/clientservice";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import useSettings from "../../../../../hooks";
import { administrationLabel } from "../../components/newcustomer/labels";
import AutocompleteClientDropDown from "../../../../../hooks/useClientList";
import { extraValues } from "../utils";
import { useTranslation } from "react-i18next";
registerLocale("fr", fr);

function AddAdministration({ setAddCustomer, id, administrationclient }) {
  const { admini_id } = useParams();
  const { empemail, activity } = useSettings();
  const [clientIdData, setClientIdData] = React.useState(null);
  const [patchclientId, setPatchClientId] = React.useState(null);
  const navigate = useNavigate();
  const {
    register,
    setValue,
    control,
    watch,
    formState,
    setError,
    handleSubmit,
  } = useForm();
  const [loader, setLoader] = React.useState(false);
  const { fields, append, remove } = useFieldArray({
    control,
    name: "team_involved",
  });
  const team_involved_field = watch("team_involved");
 

  const onSubmit = async (data) => {
    // const team_involve = data.team_involved.map((x) => x.key.value).join(",");
    // data.team_involved = team_involve;

    const x = data?.team_involved ? data?.team_involved : "";
    const flattenedArray = [].concat(...x);

    const refered_team_emaillist = data?.team_involved
      ?.map((x) => x?.value)
      ?.toString();
    data.team_involved_email = refered_team_emaillist;

    if (flattenedArray?.find((x) => x.key != "")) {
      const team_involved_data = await extraValues(
        flattenedArray?.map((x) => x?.key),
        "EMPLOYEE"
      );

      data.team_involved = team_involved_data;
    } else {
      data.team_involved = "";
    }

    setLoader(true);
    data.client_id = clientIdData?.client_id || patchclientId || id;
    const recommended_projects = await extraValues(
      data.recommended_project,
      "SERVICE"
    );
    data.recommended_project = recommended_projects;

    if (admini_id || administrationclient) {
      clientService
        ?.updateAdministration(admini_id || administrationclient, data)
        .then((res) => {
          if (res) {
            setLoader(false);
            toast.success("Administration mise à jour avec succès");
            setAddCustomer(false);
          }
        })
        .catch((error) => {
          setLoader(false);
          toast.error(Object.values(error?.msg[0]));
        });
    } else {
      clientService
        ?.addAdmini(data)
        .then((res) => {
          if (res) {
            setLoader(false);
            toast.success("Administration ajoutée avec succès");
            setAddCustomer(false);
          }
        })
        .catch((error) => {
          setLoader(false);
          toast.error(Object.values(error?.msg[0]));
        });
    }
  };
  const getAdmindetails = (data, defaultdata) => {
    const recordsplit = data?.length ? data?.split(",") : [];
    const arr = [];
    recordsplit.map((parter) => {
      const matchingItem = defaultdata.find((item) => item?.value === parter);

      if (matchingItem) {
        arr.push(matchingItem);
      }
    });
    return arr;
  };

  React.useEffect(() => {
    if (admini_id || administrationclient) {
      clientService
        .getAdminiById(admini_id || administrationclient)
        .then((res) => {
          if (res) {
            setPatchClientId(res.client_id || id);

            setValue("email", res?.email);
            const patchproject = getAdmindetails(
              res?.recommended_project,
              activity
            );

            setValue("recommended_project", patchproject);

            const team_involved_data = res?.team_involved?.length
              ? res?.team_involved.split(",")?.map((x) => x)
              : [];
            const team_involved_data_patch = empemail?.filter((data) =>
              team_involved_data?.some((x) => x == data.value)
            );
            const ref_email = res?.team_involved_email?.split(",");
            const customer_referred_data = team_involved_data_patch.map(
              (item, i) => ({
                key: item,
                value: ref_email ? ref_email?.[i] : "",
              })
            );
            setValue("team_involved", customer_referred_data);

            setValue("feedback", res?.feedback);
          }
        });
    }
  }, [admini_id, empemail, id]);

  React.useEffect(() => {
    if (team_involved_field?.length == 0) {
      append({ key: "", value: "" });
    }
  }, [team_involved_field]);
  const storeClientKeyHandler = (client) => {
    setClientIdData(client);
  };
  const clientChangehandler = (e) => {
    setValue('client', e);

   if (e === "") {

    setClientIdData(null);
  }
 
};
const { t } = useTranslation("global");

 
  console.log("patchclientId", patchclientId);
  return (
    <>
      <div className="mt-4">
        <div className="">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={12}>
                <Form.Group className="mb-3" controlId="Role">
                  <Form.Label className="text-secondary fw-bold">
                    {administrationLabel?.client}*
                  </Form.Label>

                   <Controller
                  name="client"
                  control={control}
                  rules={{ required: t("Ce champ est obligatoire") }}
                  render={({ field }) => (
                    <AutocompleteClientDropDown
                      {...field}
                      storeClientKeyHandler={storeClientKeyHandler}
                      patchClientId={patchclientId || id || null}
                      onChangeinput={(e) => {
                        field.onChange(e);
                        clientChangehandler(e);
                      }}
                    />
                  )}
                />
                   {formState?.errors?.client && (
                    <p className="text-danger">
                      {formState?.errors?.client?.message}
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={6}>
                <div>
                  <label className="form-label text-secondary fw-bold">
                   {t("Projets recommandés")}
                  </label>
                </div>
                <Controller
                  name="recommended_project"
                  control={control}
                  render={({ field }) => (
                    <CreatableSelect
                      isClearable
                      {...field}
                      isMulti
                      options={activity}
                      formatCreateLabel={(inputValue) =>
                        `Créer "${inputValue}"`
                      }
                      placeholder={t("Sélectionner")}
                    />
                  )}
                />
              </Col>
              <Col md={6}>
                <label className="form-label text-secondary fw-bold">
                  {t("Chargée de projet")}
                  <FaPlus
                    className=" ms-2"
                    size={15}
                    onClick={() => append({ key: "", value: "" })}
                    Add
                    Option
                  />
                </label>

                {fields.map((field, index) => (
                  <div className="row mb-2" key={field.id}>
                    <div className="col-md-5">
                      <Controller
                        control={control}
                        name={`team_involved[${index}].key`}
                        render={({ field }) => (
                          <CreatableSelect
                            placeholder={t("Sélectionner")}
                            {...field}
                            formatCreateLabel={(inputValue) =>
                              `Créer "${inputValue}"`
                            }
                            options={empemail}
                            onChange={(e) => {
                              setValue(`team_involved[${index}].key`, e);
                              setValue(
                                `team_involved[${index}].value`,
                                e.email
                              );
                            }}
                          />
                        )}
                      />
                    </div>
                    <div className="col-md-5">
                      <Form.Control
                        type="email"
                        {...register(`team_involved[${index}].value`)}
                        placeholder="Courriel"
                        className="placeholder-color"
                      />
                    </div>
                    <div className="col-md-2">
                      <FaRegTrashAlt
                        className="text-danger ms-2"
                        size={15}
                        onClick={() => remove(index)}
                        Remove
                      />
                    </div>
                  </div>
                ))}
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Form.Group className="mb-3" controlId="Role">
                  <Form.Label className="text-secondary fw-bold">
                    {t("Courriel")}
                  </Form.Label>
                  <Form.Control
                    type="email"
                    autofocus={false}
                    {...register("email")}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={12}>
                <Form.Group className="" controlId="lastname">
                  <Form.Label className="text-secondary fw-bold">
                    {t("Commentaires")} *
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    type="text"
                    {...register("feedback", {
                      required: t("Ce champ est obligatoire"),
                    })}
                  />
                  {formState?.errors?.feedback && (
                    <p className="text-danger">
                      {formState?.errors?.feedback?.message}
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <div className="col-md-3">
              <button
                style={{ width: "120px" }}
                className="btn btn-primary btn-sm"
                type="submit"
                disabled={loader}
              >
                {loader && (
                  <span className="spinner-border spinner-border-sm me-3"></span>
                )}
                {t(admini_id || administrationclient
                  ? "Mise à jour"
                  : "send")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddAdministration;
