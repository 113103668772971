import { useTranslation } from "react-i18next";

const GetTranslatedConstants = () => {
  const { t } = useTranslation("global");

  const typeOfOption = [
    { label: t("Membre"), value: "membre" },
    { label: t("Bénévole"), value: "bénévole" },
    { label: t("Client"), value: "client" },
  ];
  const listofGender = [
    {
      value: "Femme",
      label: t("Femme"),
    },
    {
      value: "Homme",
      label: t("Homme"),
    },
    {
      value: "Autre",
      label: t("Autre"),
    },
  ];

  const martial_status = [
    {
      value: "Célibataire",
      label: t("Célibataire"),
    },
    {
      value: "Mariée",
      label: t("Mariée"),
    },
    {
      value: "Veuve",
      label: t("Veuve"),
    },
    {
      value: "Divorcée",
      label: t("Divorcée"),
    },
    {
      value: "Séparée",
      label: t("Séparée"),
    },
    {
      value: "Union de fait",
      label: t("Union de fait"),
    },
    {
      value: "Autre",
      label: t("Autre"),
    },
  ];


  const preferred_language = [
  { label: t("Français"), value: "Français" },
  { label: t("Anglais"), value: "Anglais" },
  { label: t("Autre"), value: "Autre" },
];

  const frequency_services = [
    {
      value: "Session unique",
      label: t("Session unique"),
    },
    {
      value: "Hebdomadaire",
      label: t("Hebdomadaire"),
    },
    {
      value: "Mensuelle",
      label: t("Mensuelle"),
    },
    {
      value: "Autre",
      label: t("Autre"),
    },
  ];
   const CATEGORY = [
    {
      label: t("Contacté"),
      value: "CONTACTED",
    },
    {
      label: t("Pas de réponse"),
      value: "NOANSWER",
    },
  ];

  return { listofGender, martial_status,CATEGORY, frequency_services ,typeOfOption,preferred_language};
};

export default GetTranslatedConstants;
