import { useTranslation } from "react-i18next";


export const useLabelSetting = () => {

   const { t } = useTranslation("global");

   const messages = {
    today: "Aujourd'hui",
    previous: "Précédente",
    next: "Suivante",
    work_week:"Semaine",
    month: "Mois",
    week: "Semaine", 
    day: 'Jour',
    date: 'Date',
    time: t('Heure'),
    agenda: 'Affichage Planification',
    event: t('Événement'),
    name: 'Nom',
    noEventsInRange: "Il n'y a aucun événement dans cette plage.",
  };
return {messages}
}
const ClientApppointmentLabel = {
  title: "Ajouter des rendez-vous",
  clients: "Client",
      required_client: 'Veuillez sélectionner un client',
      message_client: 'Le texte du client est trop long',
  email: "Courriel",
      required_email: 'Veuillez entrer votre email',
  meeting_person: "Personne à la réunion",
      required_meeting_person: "Veuillez saisir le nom de la personne chargée de la réunion",
      message_meeting_person: "La personne à rencontrer est trop longue",
  organizer: "Organisatrice",
      value_organizer: "Veuillez sélectionner l'organisateur",
      required_organizer: "Veuillez sélectionner l'organisateur",
  start_date: "Date de rendez-vous",
      required_start_date: "Veuillez sélectionner des données",  
  end_date: "Date de fin",
  start_time: "Heure de début",
  end_time: "Heure de fin",
  meeting_address: "Lieu de rencontre",
      required_meeting_address: "Veuillez entrer l'l- Lieu de rencontre",
  meeting_mode: "Mode de réunion",
      required_meeting_mode: "Veuillez sélectionner le mode de réunion",
      online: 'En ligne',
      physical: 'En personne',
  additional_info: "Information additionnelle",
      required_additional_info: "Veuillez saisir des informations supplémentaires",
  save: 'Sauvegarder'
};

const appointmentLabel = {
  title:"Nouveau rendez-vous",
  clienttitle: "Nouveau rendez-vous - Cliente",
  employeetitle: "Nouveau rendez-vous - Employée",
  employee: "Employée",
      required_client: 'Veuillez sélectionner un client',
      message_client: 'Le texte du client est trop long',
  topic: "Titre",
      required_client: 'Veuillez sélectionner un client',
      message_client: 'Le texte du client est trop long',
  message: "Message",
      required_client: 'Veuillez sélectionner un client',
      message_client: 'Le texte du client est trop long',
  schedule_date: "Date de rendez-vous",
      required_start_date: "Veuillez sélectionner des données",  
  start_time: "Heure de début",
  end_time: "Heure de fin",
  save: 'Sauvegarder'
};




const formats = {
  dayFormat: (date, culture, localizer) =>
    localizer.format(date, 'dddd', culture),
};

const days = {
  Sunday: 'Dimanche',
  Monday: 'Lundi',
  Tuesday: 'Mardi',
  Wednesday: 'Mercredi',
  Thursday: 'Jeudi',
  Friday: 'Vendredi',
  Saturday: 'Samedi',
};

const months = {
  January: 'Janvier',
  February: 'Février',
  March: 'Mars',
  April: 'Avril',
  May: 'Peut',
  June: 'Juin',
  July: 'Juillet',
  August: 'Août',
  September: 'Septembre',
  October: 'Octobre',
  November: 'Novembre',
  December: 'Décembre',
};


export { appointmentLabel, days, months, formats };



const clientsLabel = {
  title: "Client",
  add_clients: "Add Clients",
  client_id: "Identité du client",
  client_name: "Nom du client",
  email: "Courriel",
  contact_number: "Numéro de contact",
  date_of_arrival: "Date d’enregistrement ",

  // Add Clients
  back: "Back",
  registration_date: "Date d’enregistrement ",
  date_of_birth: "Date de naissance",
  gender: "Genre",
  first_name: "Prénom",
  surname: "Nom de famille",
  marital_status : "État civil",
  phone  : "Téléphone",
  email : "Courriel",
  special_interests_or_needs : "Intérêts ou besoins particuliers",
  address  : "Adresse",
  arrival_date : "Date d'arrivée au",
  spoken_languages : "Langues parlées",
  native_language : "Langue maternelle",
  number_of_children : "Nombre d'enfants",
  immigration_status : "Statut d'immigration",
  income_source : "Source de revenus",
  school_level : "Niveau scolaire",
  recommended_partner : "Partenaire recommandé",
  referred_by_whom_to_afio : "Référé par qui à l’AFIO",
  date_you_service : "Date du service",
  type_of_id_submitted : "Type de pièce d’identité soumise",
  city: "Ville",
  type : "Type",
  paid : "Payé",
  native_country : "Pays d'origine",
  customer_referred_to_the_team : "Client référé à l'équipe",
  comments_or_notes : "Commentaires ou notes",
};



export { clientsLabel };

const intervenanteLabel = {
  title: "Intervenante",
  appointment_date:"Date de rendez-vous",
  add_intervenante: "Ajouter une Intervenante",
  intervener_id: "ID de l'intervenant",
  name: "Employées",
  services_offered: "Services offerts",
  mode_of_intervention: "Mode d’intervention",
  recommended_organization: "Organisations recommandées",
  feedback : "Commentaires ",
  created_on: "Créé sur",

  // Add Intervenante
  back: "Dos",
  client: "Client",
  name: "Employées",
  services_offered: "Services offerts",
  mode_of_intervention: "Mode d’intervention",
  recommended_organization: "Référé à",
  feedback : "Commentaires ",
  save : "Sauvegarder ",
};

export { intervenanteLabel };

const administrationLabel = {
  title: 'Administration',
  add_administraion: 'Ajouter une administration',
  id: "Id",
  recommended_project: 'Projets recommandés',
  team_involved: 'Équipe impliquée',
  activities: 'Projets recommandés',
  feedback: 'Commentaires',
  created_on: 'Créé sur',

  // Add Administration
  back: 'Dos',
  client: "Client",
  email : "Courriel",
  recommended_project: 'Projets recommandés',
  team_involved: 'Équipe impliquée',
   date_of_activity: 'Date d’activité',
  feedback: 'Commentaires',
  save: 'Sauvegarder',
}

const activityLabel ={
  title: 'Activités',
   id: "Id",
   date_of_activity: 'Date d’activité',
   feedback: 'Commentaires',
   save: 'Sauvegarder',
  activities: 'Activités',
  feedback: 'Commentaires',
  created_on: 'Créé sur',

}
export { administrationLabel ,activityLabel, ClientApppointmentLabel};

const messageLabel = {
  title: 'Message',
  name: "Nom",
  email: "Courriel",
  message_form: "Envoyé par",
  message: "Message",
  send: "Envoyer",
}

export {messageLabel};